// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    profileData: {
        name:'',
        email:'',
        profile_image:''
    },
    message: null,
    status: false,
    password_status:false
};

const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        getProfile(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.profileData = action.payload.data;
            } else {
                state.profileData = {};
            }
        },

        // ADD ADDRESS
        addProfileSuccess(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        deleteProfile(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        // EDIT ADDRESS
        editProjectSuccess(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        updatePasswordSuccess(state, action){
            state.message = action.payload.message;
            state.password_status = action.payload.status;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProfile() {
    return async () => {
        try {
            const response = await axios.post('/get/profile');
            dispatch(slice.actions.getProfile(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}



export function updateProfile(data) {
    return async () => {
        try {
            const response = await axios.post('/update/profile', data);
            dispatch(slice.actions.editAddressSuccess(response.data));
            dispatch(getProfile());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function updatePassword(data) {
    return async () => {
        try {
            const response = await axios.post('/update/password', data);
            dispatch(slice.actions.updatePasswordSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}