// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    companyList: [],
    message: null,
    status: false
};

const slice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        getCompanies(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.companyList = action.payload.data;
            } else {
                state.companyList = [];
            }
        },

        // ADD ADDRESS
        addCompanySuccess(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },
        updateCompanySuccess(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },
        deleteCompany(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        // EDIT ADDRESS
        editProjectSuccess(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCompanies() {
    return async () => {
        try {
            const response = await axios.post('/companyList');
            dispatch(slice.actions.getCompanies(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addCompany(companyData) {
    return async () => {
        return await axios.post('/create/company', companyData);
    };
}

export function updateCompany(data) {
    return async () => {
        return await axios.post('/update/company', data);
    };
}

export function deleteCompany(data) {
    return async () => {
        // try {
        // const response =
        return await axios.post('/company/delete', data);
        //     dispatch(slice.actions.deleteCompany(response.data));
        //     dispatch(getCompanies());
        // } catch (error) {
        //     dispatch(slice.actions.hasError(error));
        // }
    };
}
