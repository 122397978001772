// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

const initialState = {
    LabelList: [],
    message: null,
    status: false
};

const slice = createSlice({
    name: 'label',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        setLabels(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.LabelList = action.payload.data;
            } else {
                state.LabelList = [];
            }
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLabels() {
    return async () => {
        try {
            const response = await axios.post('/listLabels');
            dispatch(slice.actions.setLabels(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export const createTaskLabel = (data) => {
    return async () => {
        return await axios.post('/create/label', data);
    };
};

export const { setLabels } = slice.actions;
