import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconSettingsAutomation
} from '@tabler/icons';
import { useState } from 'react';

// constant
const icons = {
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconSettingsAutomation
};
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });
    const getPerssion = () => {
        var permissions = JSON.parse(localStorage.getItem('permissions'));
        if (!permissions) {
            permissions = [];
        }
        return permissions;
    };

    const [permissions, setPerssions] = useState(getPerssion());
    let children = [];
    if (permissions.includes('Manage Company')) {
        children.push({
            id: 'company',
            title: <FormattedMessage id="company" />,
            type: 'item',
            url: '/company',
            icon: icons.IconListCheck
        });
    }
    if (permissions.includes('Manage Department')) {
        children.push({
            id: 'department',
            title: <FormattedMessage id="department" />,
            type: 'item',
            url: '/department',
            icon: icons.IconListCheck
        });
    }
    if (permissions.includes('Manage Designation')) {
        children.push({
            id: 'designations',
            title: <FormattedMessage id="designations" />,
            type: 'item',
            url: '/designation',
            icon: icons.IconListCheck
        });
    }
    if (permissions.includes('Manage Employee')) {
        children.push({
            id: 'employee',
            title: <FormattedMessage id="employee" />,
            type: 'item',
            url: '/employee',
            icon: icons.IconListCheck
        });
    }
    if (permissions.includes('Manage Project')) {
        children.push({
            id: 'project',
            title: <FormattedMessage id="project" />,
            type: 'item',
            url: '/project',
            icon: icons.IconListCheck
        });
    }
    if (permissions.includes('Manage Employee Department Assigning')) {
        children.push({
            id: 'manage-department',
            title: <FormattedMessage id="manage-department" />,
            type: 'item',
            url: '/manage-department',
            icon: icons.IconListCheck
        });
    }
    let settings = { id: 'settings', type: 'group', children };
    return (
        <>
            {navItems}
            <NavGroup key={settings.id} item={settings} />
        </>
    );
};

export default memo(MenuList);
