// action - state management
import { LOGIN, LOGOUT, REGISTER } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    message: '',
    token: '',
    user: null,
    isInitialized: false,
    error: false
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { response } = action.payload;
            return {
                ...state
            };
        }
        case LOGIN: {
            const { isLoggedIn, message, user, token } = action.payload;
            return {
                ...state,
                isLoggedIn: isLoggedIn,
                message: message,
                user: user,
                token: token,
                isInitialized: true
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                error: false
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
