// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    manageDepartmentList: [],
    allEmployeeList: [],
    company:'',
    department:'',
    message: null,
    status: false
};

const slice = createSlice({
    name: 'manage_department',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        companyDepartmentEmployeelist(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.manageDepartmentList = action.payload.data;
            } else {
                state.manageDepartmentList = [];
            }
        },
        setCompany(state,action){
            state.company=action.payload;
        },
        setDepartment(state,action){
            state.department=action.payload;
        },
        allEmployeeList(state,action){
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.allEmployeeList = action.payload.data;
            } else {
                state.allEmployeeList = [];
            }
        }
        // // ADD ADDRESS
        // addCompanySuccess(state, action) {
        //     state.message = action.payload.message;
        //     state.status = action.payload.status;
        // },

        // deleteCompany(state, action) {
        //     state.message = action.payload.message;
        //     state.status = action.payload.status;
        // },

        // // EDIT ADDRESS
        // editProjectSuccess(state, action) {
        //     state.message = action.payload.message;
        //     state.status = action.payload.status;
        // }
    }
});

// Reducer
export default slice.reducer;
export const {setCompany,setDepartment}=slice.actions;
// ----------------------------------------------------------------------

export function getCompanyDepartmentEmployeelist(company,department) {
    return async () => {
        try {
            const response = await axios.post('/companyDepartmentEmployeelist',
                                               {company,
                                                department});
            dispatch(slice.actions.companyDepartmentEmployeelist(response.data));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export const getDepartmentEmployees = (company,department) => {
   
    return async () => {
        try {
            const response = await axios.post('all/employeeList',
                                               {company,
                                                department});
            dispatch(slice.actions.allEmployeeList(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
export const addEmployeeToDepartment= (data) => {
return async () => {
          return await axios.post('companyDepartmentEmployeeMapping',data);
     
    };
};
export const deleteEmployee = (data) => {
    return async () => {
        return await axios.post('company/department/employee/delete',data);
   
  }; 
};

export const updateDepartmentHead = (data) => {
    return async () => {
        return await axios.post('company/department/employee/update/department/head',data); 
     }; 
};

export const updateEmployeeDesignaion = (data) => {
    return async () => {
        return await axios.post('company/department/employee/update/designation',data); 
     }; 
};

export const updateManager = (data) => {
    // let URL = `company/department/employee/update/manager`;
    // return client({ url: URL, method: 'post', data: data });
    return async () => {
        return await axios.post('company/department/employee/update/manager',data); 
     }; 
};