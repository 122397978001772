// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconHelp, IconSitemap, IconListCheck, IconUserCheck, IconLayoutList, IconArticle } from '@tabler/icons';

// constant
const icons = {
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconListCheck,
    IconUserCheck,
    IconLayoutList,
    IconArticle
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    title: <FormattedMessage id="tasks" />,
    children: [
        {
            id: 'task',
            title: <FormattedMessage id="task" />,
            type: 'item',
            url: '/all-tasks',
            icon: icons.IconListCheck,
            breadcrumbs: false
        }
        // {
        //     id: 'all-tasks-board',
        //     title: <FormattedMessage id="all-tasks-board" />,
        //     type: 'item',
        //     url: '/all-tasks-board',
        //     icon: icons.IconArticle,
        //     breadcrumbs: false
        // }
    ]
};

export default other;
