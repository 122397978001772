// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    designationList: [],
    message: null,
    status: false
};

const slice = createSlice({
    name: 'designation',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        getDesignations(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.designationList = action.payload.data;
            } else {
                state.designationList = [];
            }
        },

        // ADD ADDRESS
        addDesignation(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        deleteDesignation(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        // EDIT ADDRESS
        updateDesignation(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDesignations() {
    return async () => {
        try {
            const response = await axios.post('/designation');
            dispatch(slice.actions.getDesignations(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addDesignation(companyData) {
    return async () => {
        return await axios.post('/designation/create', companyData);
    };
}

export function UpdateDesignation(data) {
    return async () => {
        return await axios.post('/designation/update', data);
    };
}

export function deleteDesignation(data) {
    return async () => {
        return await axios.post('/designation/delete', data);
    };
}
