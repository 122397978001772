// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    departmentList: [],
    message: null,
    status: false
};

const slice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        getCompanies(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.departmentList = action.payload.data;
            } else {
                state.departmentList = [];
            }
        },

        // ADD ADDRESS
        addCompanySuccess(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        deleteCompany(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        },

        // EDIT ADDRESS
        editProjectSuccess(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDepartments() {
    return async () => {
        try {
            const response = await axios.post('/departmentList');
            dispatch(slice.actions.getCompanies(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addDepartment(companyData) {
    return async () => {
        // try {
        //     const response =
        return await axios.post('/create/department', companyData);
        //     dispatch(slice.actions.addCompanySuccess(response.data));
        //     dispatch(getDepartments());
        // } catch (error) {
        //     dispatch(slice.actions.hasError(error));
        // }
    };
}

export function updateDepartment(data) {
    return async () => {
        // try {
        //     const response =
        return await axios.post('/update/department', data);
        //     dispatch(slice.actions.editAddressSuccess(response.data));
        //     dispatch(getDepartments());
        // } catch (error) {
        //     dispatch(slice.actions.hasError(error));
        // }
    };
}

export function deleteDepartment(data) {
    return async () => {
        // try {
        //     const response =
        return await axios.post('/department/delete', data);
        //     dispatch(slice.actions.deleteCompany(response.data));
        //     getDepartments();
        // } catch (error) {
        //     dispatch(slice.actions.hasError(error));
        // }
    };
}
