// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import Skeleton from '@mui/material/Skeleton';

const initialState = {
    EmployeeList: [],
    message: null,
    status: false
};

const slice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET PRODUCTS
        getEmployees(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;

            if (state.status == true) {
                state.EmployeeList = action.payload.data;
            } else {
                state.EmployeeList = [];
            }
        },

        // add employee
        addEmployeeSuccess(state, action) {
            state.message = action.payload.message;
            state.status = action.payload.status;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEmployees() {
    return async () => {
        try {
            const response = await axios.post('/employeeList');
            dispatch(slice.actions.getEmployees(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addEmployee(data) {
    return async () => {
        // try {
        //     const response =
        return await axios.post('/create/employee', data);
        //     dispatch(slice.actions.addEmployeeSuccess(response.data));
        //     dispatch(getEmployees());
        // } catch (error) {
        //     dispatch(slice.actions.hasError(error));
        // }
    };
}

export function updateEmployee(data) {
    return async () => {
        // try {
        //     const response =
        return await axios.post('/update/employee', data);
        // dispatch(slice.actions.editAddressSuccess(response.data));
        //     dispatch(getEmployees());
        // } catch (error) {
        //     dispatch(slice.actions.hasError(error));
        // }
    };
}

export function deleteEmployee(data) {
    return async () => {
        // try {
        //     const response =
        return await axios.post('/employee/delete', data);
        // dispatch(slice.actions.deleteCompany(response.data));
        //     dispatch(getEmployees());
        // } catch (error) {
        //     dispatch(slice.actions.hasError(error));
        // }
    };
}
