import other from './other';
// import settings from './settings';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [other]
};

export default menuItems;
